import styled from "styled-components";

export const StyledSidebar = styled.div`
  background: #fff;
  padding: 20px;
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  width: 140px;
  z-index: 200;
  .module {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;

    .top {
      .toggle {
        display: none;
      }
      .logo {
        margin: 0 auto;
        display: flex;
        justify-content: center;
        img {
          width: 38px;
        }
      }
      .connect {
        display: none;
      }
      .btn_connect {
        display: none;
       }
      }
    }
    hr {
      display: block;
      flex: 1 1 0px;
      max-width: 100%;
      height: 0;
      max-height: 0;
      border: solid;
      border-width: thin 0 0 0;
      transition: inherit;
      border-color: rgba(0, 0, 0, 0.12);
    }
    .nav {
      background: rgba(0, 118, 255, 0.1);

      padding: 10px;
      border-radius: 12px;
      cursor: pointer;
      .nav-content {
        display: flex;
        gap: 10px;
        align-items: center;
        flex-direction: column;
        .text {
          font-size: 14px;
          color: rgb(0, 118, 255);
          font-family: "BaiJamjuree-SemiBold";
        }
      }
    }
  }

  @media only screen and (max-width: 991px) {
    width: 100vw;
    top: 0;
    left: 0;
    height: 62px;
    padding: 0;

    .module {
      position: relative;
      &::before {
        content: none;
        display: block;
        width: 100vw;
        height: 100vh;
        background: rgb(33, 33, 33);
        opacity: 0.46;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -2;
      }
      gap: 0;
      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #f5f5f5;

        padding: 12px 20px;
        .left {
          display: flex;
          align-items: center;
          gap: 16px;
          .toggle {
            display: flex;
            svg {
              width: 24px;
            }
          }
        }
        .connect {
          padding: 8px 22px;
          border-radius: 12px;
          background: #0076ff;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: all 0.2s linear;
          font-family: BaiJamjuree-Bold;
          color: rgb(255, 255, 255);
          svg {
            width: 24px;
            path {
              fill: #fff;
            }
          }

          &:hover {
            opacity: 0.85;
          }
        }
        .btn_connect {
          padding: 8px 22px;
        border-radius: 10px;
        background: rgb(0, 118, 255);
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        gap: 8px;
        font-size: 16px;
        font-family: BaiJamjuree-Bold;
        color: rgb(255, 255, 255);
        cursor: pointer;
        transition: all 0.2s linear 0s;
        }
      }
      hr {
        display: none;
      }
      .list-nav {
        padding-top: 10px;
        background: #fff;
        display: none;
        height: 250px;

        .nav {
          justify-content: space-between;
          align-items: center;
          padding: 10px 20px;
          background: transparent;

          .nav-content {
            display: flex;
            align-items: flex-start;
            flex-direction: row;
            gap: 8px;
            .text {
              font-size: 16px;
            }
          }
        }
      }
    }

    &.isOpen {
      height: 300px;
      .module {
        &::before {
          content: "";
        }
        .list-nav {
          display: block;
        }
      }
    }
  }
`;
