import { useAccount, useBalance } from "wagmi";
import { useQuery } from "@tanstack/react-query";
import { formatEther } from "viem";

const useGetBalanceNavtive = () => {
  const { address } = useAccount();
  const {
    data: balance,
    refetch,
    isLoading: isLoadingGetBalance
  } = useBalance({
    address
  });

  const balanceConfig = balance ? formatEther(balance.value as bigint) || 0 : 0;
  const { isLoading, data } = useQuery([balanceConfig], async () => {
    return balanceConfig;
  });

  return {
    refetch,
    data,
    isLoading: isLoading || isLoadingGetBalance
  };
};

export default useGetBalanceNavtive;
