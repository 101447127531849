import { useEffect, useState } from "react";
import { StyledStaking, Styles } from "./styles";
import { IoWallet } from "react-icons/io5";

import { GoCheckCircle } from "react-icons/go";
import classNames from "classnames";
import useWindowSize from "hooks/useWindowResize";
import {
  readContracts,
  useAccount,
  useContractRead,
  useContractReads,
  useContractWrite,
  useWaitForTransaction
} from "wagmi";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { formatCurrency, sliceString } from "utils/hepler";
import STAKING_ABI from "abi/STAKING.json";
import { formatEther, parseEther } from "viem";
import useApprovalToken from "hooks/useApprovalToken";
import toast from "react-hot-toast";
import Button from "components/Button";
import Modal from "components/Modal";
import { FaAngleDown } from "react-icons/fa6";
import useGetBalanceNavtive from "hooks/useGetBalaceNative";
import useGetBalanceToken from "hooks/useGetBalaceToken";
import { formatDateTime } from "utils/dateTime";

const tokenAddress = "0x0c8F35BDed08fB706437630d89fe9070376156A2";
const stakingAddress = "0xfae1311554f6d39a7c61f79d24bae1ce96e26a6d";
const bnbAddress = "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c";
const usdtAddress = "0x55d398326f99059ff775485246999027b3197955";
const routerAddress = "0x10ed43c718714eb63d5aa57b78b54704e256024e";

const routerAbi = [
  {
    inputs: [
      { internalType: "uint256", name: "amountIn", type: "uint256" },
      { internalType: "address[]", name: "path", type: "address[]" }
    ],
    name: "getAmountsOut",
    outputs: [
      { internalType: "uint256[]", name: "amounts", type: "uint256[]" }
    ],
    stateMutability: "view",
    type: "function"
  }
];

const StakingPools = () => {
  const [price, setPrice] = useState(0);
  const { data: pools, refetch } = useContractReads({
    contracts: [
      {
        address: stakingAddress,
        abi: STAKING_ABI as any,
        functionName: "_poolInfos",
        args: [0]
      },
      {
        address: stakingAddress,
        abi: STAKING_ABI as any,
        functionName: "_poolInfos",
        args: [1]
      },
      {
        address: stakingAddress,
        abi: STAKING_ABI as any,
        functionName: "_poolInfos",
        args: [2]
      }
    ]
  });

  const { data: tokenBnbPrice } = useContractRead({
    address: routerAddress,
    abi: routerAbi as any,
    functionName: "getAmountsOut",
    args: [parseEther("1").toString(), [tokenAddress, bnbAddress]]
  });

  useEffect(() => {
    let timer = setInterval(() => {
      refetch();
    }, 2000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (tokenBnbPrice?.length) {
      readContracts({
        contracts: [
          {
            address: routerAddress,
            abi: routerAbi as any,
            functionName: "getAmountsOut",
            args: [
              tokenBnbPrice[1] ? tokenBnbPrice[1]?.toString() : "0",
              [bnbAddress, usdtAddress]
            ]
          }
        ]
      }).then((data: any) => {
        if (data && data.length && data[0]?.result) {
          const result = formatEther(data[0]?.result[1]).toString();
          setPrice(+(+result).toFixed(5));
        }
      });
    }
  }, [tokenBnbPrice]);

  return (
    <Styles>
      <div className="container">
        <div className="module">
          <div className="header">
            <p className="title">Staking</p>
            <ButtonConnect />
          </div>
          <div className="tab-module">
            <div className="tab-content">
              <div className="item active">
                <p> Your staking </p>
              </div>
              <div className="item">
                <p> Staking pools </p>
              </div>
            </div>
          </div>
          <div className="content-module">
            <div className="list-item">
              {pools
                ? pools.map((pool: any, index: number) => {
                  return (
                    <ItemExpansion
                      key={index}
                      pool={pool}
                      pId={index}
                      price={price}
                    />
                  );
                })
                : []}
            </div>
          </div>
        </div>
      </div>
    </Styles>
  );
};

export default StakingPools;

const ItemExpansion = ({ pool, pId, price }: any) => {
  const [apr, tvl, lockingTime] = pool?.result;
  const { address } = useAccount();
  const [isOpen, setOpen] = useState(false);
  const [showModal, setModal] = useState(false);
  const [amount, setAmount] = useState(null);

  const { data: nativeBalance } = useGetBalanceNavtive();
  const { data: tokenBalance } = useGetBalanceToken(tokenAddress);
  const {
    allowance,
    isLoading: approveLoading,
    approve
  } = useApprovalToken(tokenAddress, stakingAddress);

  const {
    writeAsync: stake,
    data: dataStake,
    isLoading: stakeLoad
  } = useContractWrite({
    address: stakingAddress,
    abi: STAKING_ABI as any,
    functionName: "stake",
    onError: (err) => {
      toast.error(err.message);
    }
  });

  const { isSuccess: isSuccessClaim, isLoading: stakeLoading } =
    useWaitForTransaction({
      hash: dataStake?.hash
    });

  useEffect(() => {
    if (isSuccessClaim && !stakeLoading) {
      toast.success("Stake successfully!");
      setModal(false);
    }
  }, [isSuccessClaim, stakeLoading]);

  const { writeAsync: withdraw, isLoading: widthdrawLoading } =
    useContractWrite({
      address: stakingAddress,
      abi: STAKING_ABI as any,
      functionName: "leftStaking",
      args: [pId],
      onError: (err) => {
        toast.error(err.message);
      },
      onSuccess: () => {
        toast.success("Withdraw successfully!");
      }
    });

  const {
    writeAsync: havest,
    isLoading: havestLoading,
    data: dataH
  } = useContractWrite({
    address: stakingAddress,
    abi: STAKING_ABI as any,
    functionName: "harvest",
    args: [pId],
    onError: (err) => {
      toast.error(err.message);
    },
    onSuccess: () => {
      toast.success("Havest successfully!");
    }
  });

  const { isLoading: havestLoading1 } = useWaitForTransaction({
    hash: dataH?.hash
  });

  const { data: userInfo, refetch: refetch1 } = useContractRead({
    address: stakingAddress,
    abi: STAKING_ABI as any,
    functionName: "_userStakingPools",
    args: [address, pId]
  });

  const { data: earnData, refetch: refetch2 } = useContractRead({
    address: stakingAddress,
    abi: STAKING_ABI as any,
    functionName: "getUserEarnedAmount",
    args: [address, pId]
  });

  useEffect(() => {
    let timer = setInterval(() => {
      refetch1();
      refetch2();
    }, 2000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const ptvl = tvl ? parseInt(formatEther(tvl?.toString() as any)) : 0;
  const userStaked =
    userInfo && userInfo.length
      ? parseInt(formatEther(userInfo[0]?.toString() as any))
      : 0;
  const lastEarnedTime =
    userInfo && userInfo.length ? parseInt(userInfo[2]?.toString() as any) : 0;
  const earnDebt =
    userInfo && userInfo.length
      ? parseInt(formatEther(userInfo[4]?.toString() as any))
      : 0;
  const userInterest = earnData
    ? parseInt(formatEther(earnData?.toString() as any))
    : 0;

  const now = parseInt((new Date().getTime() / 1000).toString());
  const interst =
    earnDebt +
    (userStaked * (now - lastEarnedTime) * Number(apr)) /
    1000 /
    100 /
    365 /
    24 /
    60 /
    60;

  return (
    <div className="item-group">
      <div
        className={`expansion-panel-header ${classNames({ isOpen })}`}
        onClick={() => setOpen(!isOpen)}
      >
        <div className="token">
          <div className="token-img">
            <img src="/images/logo.png" alt="" />
            <img src="/images/logo.png" alt="" className="child" />
          </div>
          <div className="token-info">
            <p className="name">BDOGE</p>
            <p className="text">Earn BDOGE</p>
          </div>
        </div>
        <div className="right">
          <div className="info-group">
            <div className="info">
              <p className="text-title">BDOGE STAKED</p>
              <p className="text-info">
                <span className="token">
                  <img src="/images/logo.png" alt="" />
                </span>
                {formatCurrency(ptvl)}
              </p>
              <p className="text">
                ~{formatCurrency((ptvl * price).toFixed(2))} USD
              </p>
            </div>
            <div className="info">
              <p className="text-title">BDOGE INTEREST</p>
              <p className="text-info">
                <span className="token">
                  <img src="/images/logo.png" alt="" />
                </span>
                {interst.toFixed(3)}
              </p>
              <p className="text">
                ~{formatCurrency((interst * price).toFixed(2))} USD
              </p>
            </div>
            <div className="info">
              <p className="text-title">TVL</p>
              <p className="text-info">
                ${formatCurrency((ptvl * price).toFixed(2))}{" "}
              </p>
            </div>
            <div className="info">
              <p className="text-title">LOCKING TIME</p>
              <p className="text-info">
                {parseInt(
                  (Number(lockingTime) / (30 * 24 * 60 * 60)).toString()
                )}{" "}
                months
              </p>
            </div>
            <div className="info">
              <p className="text-title">APR</p>
              <p className="text-info apr">{Number(apr) / 1000}%</p>
            </div>
          </div>
          <div className="icon-action">
            <FaAngleDown size={14} />
          </div>
        </div>
      </div>
      <div className={`expansion-panel-content ${classNames({ isOpen })}`}>
        <div className="left">
          <div className="info">
            <p className="text-title">Total staked</p>
            <p className="text-amount">
              ${formatCurrency((ptvl * price).toFixed(2))}
            </p>
          </div>
          {/* <div className="list-link">
            <a
              href="https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0x0c8F35BDed08fB706437630d89fe9070376156A2"
              target="_blank"
              className="link"
            >
              Buy BDOGE
            </a>
            <a
              href="https://bscscan.com/token/0x0c8f35bded08fb706437630d89fe9070376156a2"
              target="_blank"
              className="link"
            >
              View Contract
            </a>
          </div> */}
          <div className="active">
            <GoCheckCircle />
            <p>Active</p>
          </div>
        </div>
        <div className="right">
          <div className="item">
            <p className="item-title">YOUR STAKED BDOGE</p>
            <p className="item-amount">
              <span className="token">
                <img src="/images/logo.png" alt="" />
              </span>
              {formatCurrency(userStaked)}
            </p>
            <p className="item-text">
              {" "}
              ~{formatCurrency((userStaked * price).toFixed(2))} USD
            </p>
            {address ? (
              allowance?.toString() === "0" ? (
                <Button
                  onClick={() => {
                    approve();
                  }}
                  loading={approveLoading}
                  className="btn"
                >
                  Approve
                </Button>
              ) : (
                <Button className="btn" onClick={() => setModal(true)}>
                  Stake
                </Button>
              )
            ) : (
              <ButtonConnect />
            )}
          </div>
          <div className="item">
            <p className="item-title">AVAILABLE FOR WITHDRAW</p>
            <p className="item-amount">
              <span className="token">
                <img src="/images/logo.png" alt="" />
              </span>
              {formatCurrency(userStaked)}
            </p>
            <p className="item-text">
              ~{formatCurrency((userStaked * price).toFixed(2))} USD
            </p>
            {address ? (
              userStaked > 0 ? (
                <Button
                  onClick={() => {
                    withdraw();
                  }}
                  loading={widthdrawLoading}
                  className="btn"
                >
                  Withdraw{" "}
                  {userInfo &&
                    userInfo[1] &&
                    new Date(
                      Number(userInfo[1]) * 1000 + Number(lockingTime) * 1000
                    ) > new Date()
                    ? "early"
                    : ""}
                </Button>
              ) : (
                <></>
              )
            ) : (
              <ButtonConnect />
            )}
          </div>
          <div className="item">
            <p className="item-title">YOUR EARNED BDOGE</p>
            <p className="item-amount">
              <span className="token">
                <img src="/images/logo.png" alt="" />
              </span>
              {formatCurrency(interst.toFixed(3))}
            </p>
            <p className="item-text">
              ~{formatCurrency((interst * price).toFixed(2))} USD
            </p>
            {address ? (
              userInterest > 0 ? (
                <Button
                  onClick={havest}
                  loading={havestLoading || havestLoading1}
                  className="btn"
                >
                  Harvest
                </Button>
              ) : (
                <></>
              )
            ) : (
              <ButtonConnect />
            )}
          </div>
        </div>
      </div>
      {/* modal stake */}
      <Modal
        toggleModal={showModal}
        setToggleModal={setModal}
        title="Stake BDOGE"
      >
        <StyledStaking>
          <div className="staking-top">
            <div className="head">
              <div className="text text-left">Stake amount</div>
              <div className="text text-right">
                Balance: {tokenBalance?.toFixed(2)}
              </div>
            </div>
            <div className="input-group">
              <input
                value={amount as any}
                onChange={(e) => {
                  setAmount(+e.target.value as any);
                }}
              />
              <span
                className="btn-max"
                onClick={() => {
                  setAmount((tokenBalance as any) ?? 0);
                }}
              >
                Max
              </span>
            </div>
          </div>

          <div className="staking-content">
            <h3>Staking Duration</h3>
            <div className="card">
              <p className="card-title">
                {parseInt(
                  (Number(lockingTime) / (30 * 24 * 60 * 60)).toString()
                )}{" "}
                months
              </p>
              <div className="card-content">
                <div className="top">
                  <div className="item">
                    <div className="ct-left">APR:</div>
                    <div className="ct-right">{Number(apr) / 1000}% </div>
                  </div>
                  <div className="item">
                    <div className="ct-left">Est. Interest:</div>
                    <div className="ct-right">
                      {((amount ?? 0) * Number(apr)) / 1000 / 100}
                    </div>
                  </div>
                </div>
                <div className="bottom">
                  <div className="item">
                    <div className="ct-left time">Interest Start Time:</div>
                    <div className="ct-right time">
                      {formatDateTime(new Date())}
                    </div>
                  </div>
                  <div className="item">
                    <div className="ct-left time">Interest End Time:</div>
                    <div className="ct-right time">
                      {formatDateTime(
                        new Date().getTime() + Number(lockingTime) * 1000
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tx-danger">
              <span>
                You can unstake ahead of time with the fee of 20% of your staked
                tokens!
              </span>
            </div>
          </div>
          <div className="bt">
            <Button
              onClick={() => {
                // if (+(nativeBalance ?? 0) == 0)
                //   return toast.error("Balance not enough");
                // if (+(tokenBalance ?? 0) < +(amount ?? 0))
                //   return toast.error("Balance not enough");
                if ((amount ?? 0) > 0) {
                  stake({
                    args: [
                      pId,
                      parseEther((amount ?? 0).toString() as any).toString()
                    ]
                  });
                } else toast.error("Amount is invalid");
              }}
              loading={stakeLoad || stakeLoading}
            >
              Stake
            </Button>
          </div>
        </StyledStaking>
      </Modal>
    </div>
  );
};

export const ButtonConnect = () => {
  const { width } = useWindowSize();
  const { address } = useAccount();
  return (
    <ConnectButton.Custom>
      {({ openAccountModal, openConnectModal }) => {
        if (!address) {
          return (
            <button
              className="btn_connect btn connect"
              onClick={openConnectModal}
            >
              <IoWallet /> Connect {width > 1300 && "Wallet"}
            </button>
          );
        }
        return (
          <button
            className="button btn_connect"
            onClick={openAccountModal}
            type="button"
          >
            {sliceString(address, 5)}
          </button>
        );
      }}
    </ConnectButton.Custom>
  );
};
